<template>
    <div class="claims_page">
  
      <!-- grey intro -->
      <div class="bg-site-light pt-4 pb-4 mb-4">
        <div class="container-fluid px-5">
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
            <div class="flex-sm-fill">
              <h1 class="h3 font-w700 mb-0">
                Speditor claim
              </h1>

            </div>
            <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">

            </div>
          </div>
        </div>
      </div>
  
      <div class="px-md-3">
        <div class="container-fluid pt-3 pb-1 mb-5 bg-white shadow">
   
          <b-table v-if="list.length" responsive :fields="table_fields_template" striped hover :items="list">

          <template #cell(no)="data">
          <b-link :to="{name:'individualClaim', params: { claim_id: data.item._id.$oid }}">{{data.item.no}}</b-link>

          </template>
        <template #cell(created_at)="data">
          {{convertTimestampToDateString(data.item.created_at)}}
      </template>
      <template #cell(speditor_claim)="data">
          {{ speditor_claim_arg.find(e=>e.status==data.item.speditor_claim).text }}
      </template>
          </b-table>
          <div v-else class="d-flex justify-content-center">
            <h3>No speditor claims</h3>
          </div>
        </div>
      </div>
  
      
  
    </div>
  </template>

<script>
import claim from  "@/apis/Claim.js";

export default {
    components:{},
    data(){
        return {

        list:[],
        speditor_claim_arg:[
        {text:'Reklameras ej',status:0},
        {text:'Ska reklameras',status:1},
        {text:'Reklamation skickad',status:2},
        {text:'Reklamation avslogs',status:3},
        {text:'Reklamation godkänd',status:4},
        {text:'Reklamation utbetald',status:5},
      ],
        table_fields_template: [
        {
          key: 'no',
          label: 'Claim #',
          sortable: true,
        },        {
          access: {
            groups: [ // -or- definitions, only show for users with all roles from any of the groups
              ['superadmin'],
              ['puadmin'],
            ],
          },
          key: 'account',
          label: 'Account',
          sortable: true,
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        // {
        //   key: 'jobid',
        //   label: 'Source Job',
        //   sortable: true,
        //   //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        // },
        // {
        //   key: 'replacement_jobid',
        //   label: 'Replacement Job',
        //   sortable: true,
        //   //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        // },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'speditor_claim',
          label: 'Speditor claim',
          sortable: true,
        },

      ],
        }
    },
    methods:{
        getData:async function(){

            let value = await claim.getSpeditorClaim();
            this.list = value.data.list
        
        },
        convertTimestampToDateString(timestamp) {
          // Convert the timestamp to milliseconds
          const date = new Date(timestamp * 1000);

          // Extract the year, month, and day
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Months are zero-based, so add 1
          const day = date.getDate();

          // Return the date as a string in "YYYY-MM-DD" format
          return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}
    },
    computed:{

    },
    mounted(){
        this.getData();
    },
}
</script>

<style>
</style>