<script>

import { Bar } from 'vue-chartjs';

export default {
    extends:Bar,
    data(){
        return{
            chartData:null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            }
        }
    },
    props:{
        refundData:Array,
    },
    methods:{
        totalFromKeys(_object){
            let sum = 0;
            for(let objectKey in _object){
                sum+=_object[objectKey];
            }
            return sum;
        },
        assembleChartData(){
            let chartData = {
                labels:[],
                datasets:[
                    {
                        label: 'presets',
                        backgroundColor: '#f87979',
                        data: []
                    },
                    {
                        label: 'products',
                        backgroundColor: '#7acbf9',
                        data: []
                    },
                    {
                        label: 'shipments',
                        backgroundColor: '#a1a1a1',
                        data: []
                    },
                    {
                        label: 'refunds',
                        backgroundColor: 'purple',
                        data: []
                    } 
                ],
            }
            this.refundData.forEach(refund => {
                chartData.labels.push(refund.account);
                chartData.datasets[0].data.push(this.totalFromKeys(refund.presets));
                chartData.datasets[1].data.push(this.totalFromKeys(refund.products));
                chartData.datasets[2].data.push(this.totalFromKeys(refund.shipping));
                chartData.datasets[3].data.push(refund.refunds);
            });
            this.chartData = chartData;
            
        
        }
    },
    watch:{
        refundData:function(){
            this.assembleChartData();
            this.renderChart(this.chartData,this.chartOptions);
        }
    },
    mounted(){
            
            this.assembleChartData();
            this.renderChart(this.chartData,this.chartOptions);
    }
}
</script>