<template>
    <div>

    </div>
</template>

<script>


export default{
name:'wholeSale',
data(){
    return{
      wholesaleData:null,  
    }
},
methods:{
    fetchWholesaleData:async function(){
        var url = 'https://integration24.systembyran.se/wallofart-49128739422/wallofart_sample_file.json';
        var response = await fetch(url);

        console.log(response);
    }
},
created(){

},
mounted(){
    this.fetchWholesaleData();
}

}
</script>

<style scoped>

</style>