<script>
import { Doughnut,mixins} from 'vue-chartjs'
  
export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  data() {
    return {
      gradient1: null,
      gradient2: null,

      // Default options
      default_options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        legend:{
          position:'bottom',
          align:'start',
        }
      }
    }
  },
  
  props: ['chartData', 'options'],
  
  computed: {
    // Merge default options with provided options.
    chart_options: function() { 
      return Object.assign({}, this.default_options, this.options);
    },
    chart_data: function() { // SHOULD NOT BE DONE THIS WAY - START SOMEWHERE HUH?
      let data = Object.assign({}, this.chartData);



      return data;
    }
  },
  
  mounted () {
    this.renderChart(this.chartData,this.default_options);
  },
  watch:{

  // chart_data:function(){
  //    this.renderChart(this.chartData,this.default_options);
  // }

  },

}
</script>