<template>
  <div class="notfound">
    <div class="container pt-3">
      <h1>PrinterAPI</h1>
      <p class="text-info lead mb-1">{{ path }}</p>
      <p>The page you are looking for, does not exist.</p>

      <div class="text-center">
        <b-img :src="iconUrl" fluid alt="Responsive image"></b-img>
        <p class="mt-2">We are sorry, our artists have not yet painted this page.</p>
        <b-button :to="{ name: 'Dashboard' }" variant="primary" class="px-5">Return to home</b-button>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapActions, mapGetters } from 'vuex'

export default {
  name: "NotFound",

  data() {
    return {}
  },

  computed: {
    path() {
      return window.location.pathname
    },
    iconUrl() {
      return require('@/assets/404_logo.svg')
    },
  },

  created() {
    // console.log( this.skuExists({sku: 'ST-5070-WHI', account: ''}) );
  },

  methods: {
  }
};
</script>

<style scoped>
  img {
    max-height: 200px;
    width: auto;
  }
</style>

