<template>
    <div >
        <div class="bg-site-light pt-4 pb-4 mb-4">
          <div class="container-fluid px-5">
            <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
              <div class="flex-sm-fill">
                <h1 class="h3 font-w700 mb-2">
                  Claim report
                </h1>
                <h2 class="h6 font-w500 text-muted mb-0">
                  {{userHasAnyOfRoles(['superadmin'])?'Preview':'invalid user'}}
                </h2>
              </div>
              <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">


              </div>
            </div>
          </div>
        </div>
        

      
       <div class="row justify-content-center   m-3" v-if="userHasAnyOfRoles(['superadmin'])">
         
           <div class="col-lg-8 shadow-sm m-2 p-4 bg-light border border-light" v-if="userAccounts">

            <b-form-group
            v-if="userAccounts.length > 1"
            label="Account"
            label-for=""
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
          <multiselect 
            placeholder="All accounts"
            v-model="accounts.account"
            trackBy="account"
            label="name"
            value-field="name"
            size="sm"
            :options="[{name:'All accounts',account:''},...userAccounts]"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            
        
          ></multiselect>
          </b-form-group>
    
            <b-form-group
            label="From"
            label-for=""
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="accounts.rangeFrom" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select from limit" ></b-form-datepicker>
          </b-form-group>

          <!-- To date -->
          <b-form-group
            label="To"
            label-for=""
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="accounts.rangeTo" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select to limit" ></b-form-datepicker>
  
          </b-form-group>
          <div class="row justify-content-end mt-4">
          <b-button variant="primary" class="mr-3 pr-3" @click="queryReport()">GET REPORT</b-button>
          </div>
      
        </div>
            <div class="col-lg-8 bg-light shadow-sm m-2">

                <div v-if="reportData" class="row justify-content-around m-3 border-bottom border-secondary" ><div class="mb-1"><h6><b> Account:{{accounts.account.name||' All'}}</b></h6> </div><div class="mb-1"><h6><b>From:{{accounts.rangeFrom||from||' -\u221E'}}</b></h6></div><div class="mb-1"><h6><b>To:{{accounts.rangeTo||to||' \u221E'}}</b></h6></div></div>

                <div class="row justify-content-center ">
                <div class="col-12 justify-content-center" v-if="reportData">
                <div v-for="(data,index) in theData" :key="index" class="row justify-content-center">
                <div v-if="reportData" class="col-lg-7 col-md-12 m-2 p-3">
                    <b-table striped hover :fields="fields" v-if="data" class="text-right" :items="renderStatisticsTable(data.data,data.count)" sortable></b-table>
                </div>

                <div class="col-lg-4 col-md-6 m-2" v-if="reportData">
                  <Doughnut  :chartData="renderStatisticsWheel(data.data,data.color)" class="p-2"/>
                </div> 
                </div>
                </div>
                
            </div>
        </div>     
       </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import claim from  "@/apis/Claim.js";

import Doughnut from '../components/Doughnut.vue';


export default {
    
        components:{
         Doughnut
        },
        data(){
        return{
              from:null,
              to:null,
              accounts:{
                  account:"",
                  rangeFrom:"",
                  rangeTo:""
              },
              reportData:null,
              fields:['Category','Amount','Procent'],
              theData:null
             }
             
    },
    methods:{
       async queryReport(){//claim report request
            var sendObj= {...this.accounts};
            if(!this.accounts.account) {
              sendObj.account =""; 
            }else{
              sendObj.account =  this.accounts.account.account;
            }
            var response = await claim.getReport(sendObj);
            console.log(response.data);
            this.reportData = response.data;
            this.theData = [];
            this.theData.push({data:this.reportData.categories,color:['red','violet','blue','yellow'],count:this.reportData.count});
            this.theData.push({data:this.reportData.statuses,color:['red','green','blue','yellow'],count:this.reportData.count});
            this.theData.push({data:this.reportData.returnTags,color:false,count:this.reportData.tag_count});
            this.to = this.reportData.to;
            this.from = this.reportData.from;
        },

        getRandomColor(){
          var color = `rgb(${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)})`
          return color;
        },
        renderStatisticsWheel(_data,_color){
          var chartTemplate  = {// chart js object 
                  hoverBackgroundColor: "red",
                  hoverBorderWidth: 10,
                  labels: [],
                  datasets: [
                      {
                          label: "Data One",
                          backgroundColor: [],//'blue','green','red','purple'
                          data: []
                      }
                  ]
              }
              var i = 0;
            for(const element in _data){
              chartTemplate.labels.push(element);
              chartTemplate.datasets[0].data.push(_data[element]);
              if(!_color){
                chartTemplate.datasets[0].backgroundColor.push(this.getRandomColor());
              }else{
                chartTemplate.datasets[0].backgroundColor.push(_color[i]);
              }
              i++;
            }
            return chartTemplate;
        },
        renderStatisticsTable(_data,_count){
              var tableData = []
          for(const element in _data){
              tableData.push({Category:element,Amount:_data[element],Procent:Math.round((_data[element]/_count)*100)})
          }
          return tableData;
        }

    },

      computed: {
  ...mapGetters([
      'getPageSettings',
      'timestampAsDate',
      'availableDeliveryCountries',
      'countryByCode',
    ]),
    ...mapGetters('user', [
      'currentUser',
      'userAccounts',
      'userAccountName',
      'userHasAnyOfRoles',
    ]),

  },
    mounted(){
   
    }
}
</script>