<template>
    <div >
        <div v-if="shipping">
        <div class="row justify-content-end shadow-sm" style="background-color:rgba(254,204,0,255);" v-if="shipping.speditor=='DHLROAD'"><img src="@/assets/DHL_Logo.svg" height="15%" alt="" class="m-3 pr-4"> </div>
        <div class="row justify-content-end shadow-sm" style="background-color:rgba(0,0,0,0);" v-if="shipping.speditor=='FEDEX'"><img src="@/assets/FEDEX.svg" height="15%" alt="" class="m-3 pr-4"> </div>
        </div>
        <b-alert variant="success" class="text-center noselect" style="position:relative !important; top:0px;"  :show="showAlert" dismissible :fade="true">Copied to clipboard  <b-icon icon="clipboard-check" shift-h="4"></b-icon></b-alert>
        <div class=" m-1 row justify-content-center" id="mailContent" v-if="claim" v-bind:class="{ selected : isSelected }">
            
       <div class="col-lg-7 bg-white shadow m-3" >
        <div v-if="job" >
                    <div class="row justify-content-end m-2 mt-3 mr-1">
                       <b-button class="" @click="CopyToClipboard('mailContent');showAlert=true;timeDelay(3000) " variant="success">MAIL{{' '}} <b-icon icon="envelope" ></b-icon></b-button>            
                    </div>
          <div v-if="!shipping">
                    <!-- this part should not be used -->
                <div class="mb-2 mt-4" ><b>Claim</b></div>
                    <div>JOB ID: {{claim.claim.jobid}}</div> 
                    <div v-if="claim.claim.categories.defect_packaging">reason: defect packaging</div>
                    <div v-if="claim.claim.categories.transport_damage">reason: damaged during transport</div>
                    <div v-if="claim.claim.categories.wrong_packaging">reason: wrong content in package</div>
                    <div v-if="claim.claim.categories.lost_transport">reason: lost during transport</div>
                    <div>  Item description: {{claim.claim.claim_text}}</div>   
            
                <div v-if="shipping" ><b>Shipping</b> 
                    <div class="mt-2"> speditor: {{ shipping.speditor}}</div>    
                    <div> shipment number: {{ shipping.shipmentNo}}</div> 
                    <div> parcel number: {{ shipping.parcels[shipping.parcels.length-1].parcelNo}}</div> 
                </div> 
           
                <div class="mt-2 mb-2"><b>Additional text</b></div>
                <div v-if="claim.claim.categories.defect_packaging" class="col-6">
                    FJDAKLÖFJDASÖKLFDA
                    FDAFDASFDSAFDSA\n 
                    fdasfdsafdasfdas
                </div>
                <div v-if="claim.claim.categories.lost_transport">

                </div>
                <div v-if="claim.claim.categories.transport_damage">

                </div>
                <div v-if="claim.claim.categories.wrong_packaging">

                </div>
                <div v-if="claim.claim.images.length" class="mb-2 mt-2"><b>Images</b> </div>
                     <!-- this part should not be used -->
               </div> 
             <div v-if="shipping">  <!-- DHL ROAD-->
                <div v-if="shipping.speditor=='DHLROAD'">
                    <div class="mb-1"><b>Reklamation DHL freight</b></div>
                    <div>Sändnignsnummer:{{shipping.shipmentNo}}</div>
                    <div>Antal koli:{{1}}</div>
                    <div>Vikt:{{shipping.weight+' KG'}}</div>
                
                    <div>Typ av skada:SYNLIG</div>
                    <div>Skadans omfattning: TOTAL</div>
                    <div>Ytteremballage:</div>
                    <div>Inneremballage:</div>
                
                    <div class="row " style="margin-left:1px">Uppskattat skadekostnad: <div :class="{'text-danger':calcPrice(job.fulldata.items)=='unknown'}" >{{calcPrice(job.fulldata.items)}}</div>  </div> 
                    <div v-if="claim.claim.claim_text"> Felbeskrivning: {{claim.claim.claim_text}}</div>  
                    <div>Innehåll:</div>
                    <div> <div v-for="item in job.fulldata.items" :key="item.line_id" class="ml-3 mt-1 mb-1">{{item.quantity+'x '+item.name}}</div>
                    </div>   
                    <div v-if="claim.claim.images.length" class="mb-2 mt-2"><b>Bilder</b> </div>
                </div> <!-- DHLROAD-->
                </div><!-- FEDEX-->
                <div v-if="shipping">
                <div v-if="shipping.speditor=='FEDEX'">
                    <div class="mb-1"><b>FEDEX Claim</b></div>
                    <div>Shippmentnumber:{{shipping.shipmentNo}}</div>
                    <div>Parcel Amount:{{1}}</div>
                    <div>Weight:{{shipping.weight+' KG'}}</div>
            
                    <div>Shippment information: DAMAGED</div>
                    <div>outer packaging:</div>
                    <div>inner packaging:</div>
                
                    <div class="row " style="margin-left:1px">Cost: <div :class="{'text-danger':calcPrice(job.fulldata.items)=='unknown'}" >{{calcPrice(job.fulldata.items)}}</div>  </div> 
                    <div v-if="claim.claim.claim_text"> Claim description: {{claim.claim.claim_text}}</div>  
                    <div>Content:</div>
                    <div> <div v-for="item in job.fulldata.items" :key="item.line_id" class="ml-3 mt-1 mb-1">{{item.quantity+'x '+item.name}}</div>
                    </div>   
                    <div v-if="claim.claim.images.length" class="mb-2 mt-2"><b v-if="shipping.speditor=='DHLROAD'">Bilder</b> <b v-if="shipping.speditor=='FEDEX'">Images</b> </div>
            
                </div><!-- FEDEX-->
                </div>
                <div class="row justify-content-left   m-1">
                    <img class="m-1" v-for="(data,index) in claim.claim.images" :key="index" :src="data" alt="" height="300px">
                </div>
         </div>
        </div>
       </div>
    </div>
</template>

<script>
import Job from '@/apis/Job.js'
import indClaims from "@/apis/Claim.js";
export default {
data(){
    return{
        claim:null,
        isSelected:false,
        job:null,
        shipping:null,
        showAlert:false,
        
    }
},    
props:{

},
methods:{
   async fetchDATA(){
       var res = await indClaims.individualClaim(this.$route.params.claim_id);    
       this.claim = await{...res.data}
       
       var response = await  Job.fetch(this.claim.claim.job_id);
       
       this.job =  await {...response.data.job};
       this.shipping =   response.data.job.fulldata.shipping[response.data.job.fulldata.shipping.length-1];
       console.log(this.job,this.shipping)
       return true;
    },
     CopyToClipboard(containerid) {
         window.getSelection().removeAllRanges();
         //console.log("it got copied",containerid);
        if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(document.getElementById(containerid));
            range.select().createTextRange();
            document.execCommand("copy");
        } else if (window.getSelection) {
            range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            window.getSelection().addRange(range);
            document.execCommand("copy");

       }
    this.openMail();

},

  timeDelay(_time){
      setTimeout(()=>{this.showAlert = false},_time);
  },
  calcPrice(_items){
    var price =0;
    var currency ='';
        if(_items[0].currency){
            currency = _items[0].currency; 
        }
        _items.forEach(item => {
            if(item.price){
                price+= parseFloat(item.price);
            }
        });

        if(!price){
            return 'unknown';
        }

    return price+' '+currency;
},
openMail(){

    if(this.shipping?.speditor==='DHLROAD'){
        const subject = 'Reklamation '+this.shipping.shipmentNo;
        const body = ' %0D%0APASTE TEXT HERE (CTRL+V)';
        const mail = 'somemail@bblabla.com';
        window.location.href = `mailto:${mail}?subject=${subject}&body=${body}`;
        return
    }
    if(this.shipping?.speditor==='FEDEX'){
        const subject = 'Claim '+this.shipping.shipmentNo;
        const body = '%0D%0APASTE TEXT HERE (CTRL+V)';
        const mail = 'somemail@bblabla.com'; 
        window.location.href = `mailto:${mail}?subject=${subject}&body=${body}`;
    }else{
        const subject = 'Claim ';
        const body = '%0D%0APASTE TEXT HERE (CTRL+V)';
        const mail = 'somemail@bblabla.com'; 
        window.location.href = `mailto:${mail}?subject=${subject}&body=${body}`;
    }
}   
  


},

mounted(){

},
created(){
   this.fetchDATA();
  }
}
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */ 
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selected{
    user-select: all;

}
</style>