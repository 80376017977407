<template>
  <div>
    <div class="bg-site-light pt-4 pb-4 mb-4">
      <div class="container-fluid px-5">
        <div
          class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left"
        >
          <div class="flex-sm-fill">
            <h1 class="h3 font-w700 mb-2">Refund report</h1>
            <h2 class="h6 font-w500 text-muted mb-0">
              {{
                userHasAnyOfRoles(["superadmin",'puadmin']) ? "Preview" : "invalid user"
              }}
            </h2>
          </div>
          <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0"></div>
        </div>
      </div>
    </div>
    <div class="contianer">
      <div
        class="row row-cols-1 justify-content-center"
        v-if="userHasAnyOfRoles(['superadmin','puadmin'])"
      >
        <div class="col-lg-8 col-xl-6 col-md-8">
          <div
            class="shadow-sm p-4 bg-light border border-light"
            v-if="userAccounts"
          >
            <b-form-group
              v-if="userAccounts.length > 1"
              label="Account"
              label-for=""
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              class="mb-1"
            >
              <multiselect
                placeholder="All accounts"
                v-model="accounts.account"
                trackBy="account"
                label="name"
                value-field="name"
                size="sm"
                :options="[
                  { name: 'All accounts', account: '' },
                  ...userAccounts,
                ]"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              label="From"
              label-for=""
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              class="mb-1"
            >
              <b-form-datepicker
                size="sm"
                class="border-radius-none"
                v-model="accounts.rangeFrom"
                start-weekday="1"
                min="2016-01-01"
                :reset-button="true"
                locale="en"
                placeholder="Select from limit"
              ></b-form-datepicker>
            </b-form-group>

            <!-- To date -->
            <b-form-group
              label="To"
              label-for=""
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              class="mb-1"
            >
              <b-form-datepicker
                size="sm"
                class="border-radius-none"
                v-model="accounts.rangeTo"
                start-weekday="1"
                min="2016-01-01"
                :reset-button="true"
                locale="en"
                placeholder="Select to limit"
              ></b-form-datepicker>
            </b-form-group>
            <div class="row justify-content-end mt-4">
              <b-button
                variant="primary "
                size="sm"
                class="mr-3"
                @click="queryReport()"
                >GET REPORT</b-button
              >
            </div>
          </div>
          <div v-if="assembledData.length">

          
          <div
            
            class="bg-light mt-2 p-4  shadow-sm"
            
          >
            <div>
              <div class="my-3 mb-3 d-flex justify-content-between border-bottom align-items-end pb-1">
                <div class="d-flex align-items-baseline">
                  <div style="font-size:1.5rem;vertical-align: bottom;" class="" >

                 
                    <!-- <b style="font-size:1.5rem;"> -->
                      Total refunds
                      {{
                        assembledData
                          .map((r) => r.refunds)
                          .reduce((a, b) => a + b)
                      }}
                    <!-- </b> -->
                   </div>
                </div>
                <div>
                  <b-button
                    @click="showChart = !showChart"
                    size="sm"
                    variant="dark"
                    >{{ showChart ? "hide" : "show" }}</b-button
                  >
                </div>
              </div>
              <div class="d-flex justify-content-around pb-2">
                <div class="">
                  shipments
                  <b-badge>{{
                    assembledData
                      .map((obj) => totalFromKeys(obj.shipping))
                      .reduce((a, b) => a + b)
                  }}</b-badge>
                </div>
                <div class="">
                  products
                  <b-badge>{{
                    assembledData
                      .map((obj) => totalFromKeys(obj.products))
                      .reduce((a, b) => a + b)
                  }}</b-badge>
                </div>
                <div class="">
                  presets
                  <b-badge>{{
                    assembledData
                      .map((obj) => totalFromKeys(obj.presets))
                      .reduce((a, b) => a + b)
                  }}</b-badge>
                </div>
              </div>
              <transition name="fade">
                <div v-show="showChart" ref="refparent">
                  <div>
                    <div
                      class="d-block justify-content-center"
                      style="width: 100%h;height:500px;overflow-x: scroll;"
                    >
                      <RefundChart
                        :style="`margin-top: 20px;height: 500px;width:${scrollMany(
                          assembledData.length
                        )}`"
                        :refundData="assembledData"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          </div>
          
          <div class="bg-light mt-2 p-4 shadow-sm border" v-if="assembledData.length">
            <div
              class="mb-3 mt-3 p-1 shadow-sm border"
              v-for="object in assembledData"
              :key="object.account"
            >
              <div>
                <div
                  :class="`${object.refunds ? 'border-bottom' : ''} mt-2 pl-3`"
                >
                  <h3>
                    <b>{{ object.account }}</b>
                  </h3>
                  <div class="">
                    {{ object.period[0] }} to {{ object.period[1] }}
                  </div>
                  <div>{{ refundText(object.refunds) }}</div>
                </div>

                <div class="ml-3">
                  <div
                    class="mb-2 mt-2"
                    v-if="Object.keys(object.shipping).length"
                  >
                    <h5>Shipments</h5>
                    <div
                      v-for="(ship, shipname) in object.shipping"
                      :key="shipname"
                    >
                      <div class="d-flex inline mb-2">
                        <li class="ml-3">{{ shipname }} {{ ship }}</li>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mb-2 mt-2"
                    v-if="Object.keys(object.presets).length"
                  >
                    <h5>Presets</h5>
                    <div
                      v-for="(print, printname) in object.presets"
                      :key="printname"
                    >
                      <div class="d-flex inline">
                        <li class="ml-3">{{ print }} x {{ printname }}</li>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mb-2 mt-2"
                    v-if="Object.keys(object.products).length"
                  >
                    <h5>Products</h5>
                    <div
                      v-for="(prod, prodname) in object.products"
                      :key="prodname"
                    >
                      <div class="d-flex inline mb-2">
                        <li class="ml-3">{{ prod }} x {{ prodname }}</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import claim from "@/apis/Claim.js";

import RefundChart from "../components/RefundChart.vue";

export default {
  components: { RefundChart },
  data() {
    return {
      from: null,
      to: null,
      accounts: {
        account: "",
        rangeFrom: "",
        rangeTo: "",
      },
      reportData: null,
      showChart: true,
      dataByAccount: {},
      assembledData: [],
      screenWidth: null,
    };
  },
  methods: {
    seperateByAccount(reports) {
      let object = {};
      for (let report of reports) {
        if (!object[report.account]) {
          object[report.account] = [];
        }
        object[report.account].push(report);
      }

      return object;
    },
    organizeData(accountsObject) {
      let arg = [];

      for (let accountName in accountsObject) {
        let newObject = {};
        newObject.account = accountName;
        newObject.period = [this.from, this.to];
        newObject.refunds = 0;
        let shipping = {};
        let presets = {};
        let products = {};

        for (let report of accountsObject[accountName]) {
          if (report?.refund) {
            newObject.refunds += 1;
          } else {
            continue;
          }

          if (report?.shipment) {
            let shipment = report.shipment;
            if (Array.isArray(shipment)) {
              shipment.forEach((ship) => {
                let country = report?.delivery_address?.country
                  ? report.delivery_address.country
                  : "";

                let shippingKey = `${ship.speditor}/${ship.service}:${country}`;

                if (!shipping[shippingKey]) {
                  shipping[shippingKey] = 0;
                }
                shipping[shippingKey] += 1;
              });
            }
          }
          if (report?.items) {
            let items = report.items;
            if (Array.isArray(report.items)) {
              items.forEach((item) => {
                if (item.line_type == "document") {
                  if (!presets[item.preset]) {
                    presets[item.preset] = 0;
                  }
                  presets[item.preset] += Number(item.quantity);
                }
                if (item.line_type == "product") {
                  if (!products[item.sku]) {
                    products[item.sku] = 0;
                  }
                  products[item.sku] += Number(item.quantity);
                }
              });
            }
          }
        }
        newObject.shipping = shipping;
        newObject.presets = presets;
        newObject.products = products;
        arg.push(newObject);
      }
      return arg;
    },
    async queryReport() {
      //claim report request
      var sendObj = { ...this.accounts };
      if (!this.accounts.account) {
        sendObj.account = "";
      } else {
        sendObj.account = this.accounts.account.account;
      }
      var response = await claim.getReport(sendObj);
      this.reportData = response.data;
      this.to = this.reportData.to;
      this.from = this.reportData.from;

      this.dataByAccount = this.seperateByAccount(response.data.report);
      this.assembledData = this.organizeData(this.dataByAccount);
      console.log(this.assembledData);
    },

    totalFromKeys(_object) {
      let sum = 0;
      for (let objectKey in _object) {
        sum += _object[objectKey];
      }
      return sum;
    },
    refundText(_refund) {
      if (!_refund) {
        return "No refunds";
      }
      if (_refund > 1) {
        return _refund + " refunds";
      } else {
        return _refund + " refund";
      }
    },
    scrollMany(length) {
      if (!length) {
        return "100%;";
      }
      if (length > 5) {
        let width = this.screenWidth ? this.screenWidth / (length*1.3) : 150;
        return length * width + "px;";
      }
      return "100%;";
    },
  },

  computed: {
    ...mapGetters([
      "getPageSettings",
      "timestampAsDate",
      "availableDeliveryCountries",
      "countryByCode",
    ]),
    ...mapGetters("user", [
      "currentUser",
      "userAccounts",
      "userAccountName",
      "userHasAnyOfRoles",
    ]),
  },
  mounted() {
    this.screenWidth = window.innerWidth>window.innerHeight?window.innerWidth:window.innerHeight;
    window.addEventListener('resize',()=>{
      this.screenWidth = window.innerWidth>window.innerHeight?window.innerWidth:window.innerHeight;
    })
    //console.log(this.$refs.refparent);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>